import React from "react"
import ArticlesGrid from "../components/ArticlesGrid"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ArticlePage = () => (
  <div className="stripes-parallax">
    <Layout activePage="articles">
      <ArticlesGrid />
    </Layout>
  </div>
)

export const Head = () => (
  <SEO
    title="Articles"
    description="Follow my projects in these blog posts: ultrasonic array, cake stencils, and more!"
  />
)

export default ArticlePage
