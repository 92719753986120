import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "../../../devices"

export const ArticleBox = styled(Link)`
  padding: 16px;
  margin: 0 auto;
  background-color: #222;
  transition: 0.2s;
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
  text-align: left;
  border: 6px solid rgb(0, 153, 153);
  border-radius: 24px;
  display: ${props => (props.visible === "true" ? "block" : "none")};

  &:hover {
    box-shadow: 0px 8px 16px 0px rgb(0, 0, 0, 0.6);
    border: 6px solid aqua;
  }

  @media ${device.mobile} {
    border-radius: 30px;
  }
`

export const ImageWrapper = styled.div`
  border-radius: 18px;
  overflow: hidden;
  max-height: 40vh;

  @media ${device.mobile} {
    border-radius: 24px;
  }
`

export const SeperatorBar = styled.div`
  width: 80%;
  border-radius: 4px;
  height: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: rgb(115, 32, 135);
`

export const Title = styled.h3`
  font-weight: lighter;
  color: #fff;
  margin-top: 12px;
`

export const Excerpt = styled.p`
  color: #ccc;
  font-size: 0.95rem;
`

export const Tags = styled.p`
  color: #ccc;
  font-size: 0.8rem;
`
