import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "../../../devices"

export const ArticlesSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ArticlesSectionTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: 300;
  padding: 8px 20px;
  border: 4px solid rgb(115, 32, 135);
  color: #222;
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 36px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
`
export const FiltersContainer = styled.ul`
  display: flex;
  margin-bottom: 20px;

  @media ${device.mobile} {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
  }
`

export const FilterBox = styled.li`
  list-style: none;
  font-size: 1rem;
  padding: 6px 8px;
  border-radius: 10px;
  margin: 0px 8px;
  background-color: #222;
  transition: 0.3s;
  color: #fff;
  background-color: ${props => (props.state ? "rgb(115, 32, 135)" : "#222")};
  border: ${props =>
    props.state ? "3px solid rgb(115, 32, 135)" : "3px solid #222"};

  &:hover {
    box-shadow: 0px 8px 16px 0px rgb(0, 0, 0, 0.6);
    border: ${props => (props.state ? "3px solid aqua" : "3px solid aqua")};
  }
`

export const FilterBoxController = styled.li`
  list-style: none;
  font-size: 1rem;
  padding: 6px 8px;
  border-radius: 10px;
  margin: 0px 8px;
  background-color: #222;
  transition: 0.3s;
  color: #fff;
  background-color: ${props => (props.state ? "rgb(0, 153, 153)" : "#222")};
  border: ${props =>
    props.state ? "3px solid rgb(0, 153, 153)" : "3px solid #222"};

  &:hover {
    box-shadow: 0px 8px 16px 0px rgb(0, 0, 0, 0.6);
    border: ${props => (props.state ? "3px solid aqua" : "3px solid aqua")};
  }
`

export const ArticlesCardGrid = styled.div`
  margin: 10px 20px 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-self: stretch;

  padding: 60px 4vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 6vh;
  grid-column-gap: 6vw;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }
`
