import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import {
  ArticleBox,
  Excerpt,
  ImageWrapper,
  SeperatorBar,
  Tags,
  Title,
} from "./ArticleCardElements"
import PropTypes from "prop-types"

function ArticleCard({
  image,
  title,
  height,
  width,
  to,
  description,
  tags,
  visible,
}) {
  return (
    <>
      <ArticleBox to={to} visible={visible.toString()}>
        <ImageWrapper>
          <GatsbyImage
            image={image}
            alt={title}
            width={width}
            height={height}
            objectPosition={"left top"}
            imgStyle={{ borderRadius: "3%" }}
          />
        </ImageWrapper>
        <Title>{title}</Title>
        <SeperatorBar />
        <Excerpt>{description}</Excerpt>
      </ArticleBox>
    </>
  )
}

//<Tags>Tags: {tags.join(", ")}</Tags>

ArticleCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  alt: PropTypes.string,
  visible: PropTypes.bool,
}

export default ArticleCard
