import React, { useState, useEffect } from "react"

import ArticleCard from "../ArticleCard"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import {
  ArticlesCardGrid,
  ArticlesSectionTitle,
  ArticlesSectionContainer,
  FiltersContainer,
  FilterBox,
  FilterBoxController,
} from "./ArticlesGridElements"

function ArticlesGrid() {
  const data = useStaticQuery(graphql`
    query ArticlesGridQuery {
      allMdx {
        nodes {
          frontmatter {
            featured_image {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 800
                  height: 600
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
            title
            slug
            date_published
            description
            tags
          }
          id
        }
      }
    }
  `)

  // Generate a list of tags from the articles
  function getAllFilters(articles) {
    const filters = []
    for (let i = 0; i < articles.length; i++) {
      let article = articles[i]
      let tags = article.frontmatter.tags
      for (let j = 0; j < tags.length; j++) {
        let tag = tags[j]
        if (!filters.includes(tag)) {
          filters.push(tag)
        }
      }
    }
    return filters
  }

  // Check if all filters are selected
  function checkAllFilters(filters) {
    if (Object.values(filters).includes(false)) {
      return false
    }
    return true
  }

  // Check if no filters are selected
  function checkNoFilters(filters) {
    if (Object.values(filters).includes(true)) {
      return false
    }
    return true
  }

  // Generate dictionary with intial states for all filter buttons
  function getIntialVisibilities(filters, initialState) {
    const initialVisibilities = {}
    filters.forEach(filter => (initialVisibilities[filter] = initialState))
    return initialVisibilities
  }

  function isArticeVisible(tags, tagSelections) {
    for (let i = 0; i < tags.length; i++) {
      let tag = tags[i]
      if (tagSelections[tag]) {
        return true
      }
    }
    return false
  }

  const articles = data.allMdx.nodes

  const filters = getAllFilters(articles)

  const initialVisibilities = getIntialVisibilities(filters, true)

  const [tagSelections, setVisibilities] = useState(initialVisibilities)

  function setAllStates(filters, state) {
    let newFilters = { ...filters }
    for (var key in filters) {
      newFilters[key] = state
    }
    setVisibilities(newFilters)
  }

  function updateSelectionIndividual(tag, setVisibilities) {
    let newTagSelections = { ...tagSelections }
    newTagSelections[tag] = !tagSelections[tag]
    setVisibilities(newTagSelections)
  }

  return (
    <ArticlesSectionContainer>
      <ArticlesSectionTitle>Articles</ArticlesSectionTitle>
      <FiltersContainer>
        {filters.map(filter => (
          <FilterBox
            onClick={() => updateSelectionIndividual(filter, setVisibilities)}
            state={tagSelections[filter]}
            key={filter}
          >
            {filter}
          </FilterBox>
        ))}
      </FiltersContainer>
      <FiltersContainer>
        <FilterBoxController
          state={checkAllFilters(tagSelections)}
          onClick={() => setAllStates(tagSelections, true)}
        >
          All
        </FilterBoxController>
        <FilterBoxController
          state={checkNoFilters(tagSelections)}
          onClick={() => setAllStates(tagSelections, false)}
        >
          None
        </FilterBoxController>
      </FiltersContainer>
      <ArticlesCardGrid>
        {articles.map(article => (
          <ArticleCard
            image={getImage(article.frontmatter.featured_image)}
            title={article.frontmatter.title}
            description={article.frontmatter.description}
            key={article.id}
            height={200}
            width={200}
            to={"/articles/" + article.frontmatter.slug}
            tags={article.frontmatter.tags}
            visible={isArticeVisible(article.frontmatter.tags, tagSelections)}
          />
        ))}
      </ArticlesCardGrid>
    </ArticlesSectionContainer>
  )
}

export default ArticlesGrid
